<template>
  <section class="height-spacer width-spacer">
    <div class="meshing-description">
      <h2 class="h3">
        {{ $t('seoMeshing.title') }}
      </h2>
      <p>{{ $t('seoMeshing.subtitle') }}</p>
    </div>
    <TabsBar
      :tabs="Object.keys(Meshing)"
      :selected-tab="selectedTab"
      @select-tab="changeSelectedTab"
    />
    <TabsContent>
      <li
        v-for="[MeshName, Mesh] of Object.entries(Meshing)"
        :key="MeshName"
        :class="{ active: MeshName === selectedTab }"
      >
        <ul>
          <li
            v-for="meshEntry of Mesh"
            :key="meshEntry.dataUx"
          >
            <SeoLink
              :href="meshEntry.href"
              :data-ux="meshEntry.dataUx"
              :text="meshEntry.text"
            />
          </li>
        </ul>
      </li>
    </TabsContent>
  </section>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import SeoLink from '@/domains/design_system/molecules/SeoLink/SeoLink.vue'
import TabsBar from '@/domains/design_system/molecules/Tabs/TabsBar.vue'
import TabsContent from '@/domains/design_system/molecules/Tabs/TabsContent.vue'
import {
  Meshing,
  type MeshTypes
} from '@/services/seoMeshing.service'

const [firstKeyInMeshing] = Object.keys(Meshing)
const selectedTab = ref(firstKeyInMeshing)

const changeSelectedTab = (tab: MeshTypes) => {
  selectedTab.value = tab
}
</script>

<style scoped lang="scss">
.tabContent ul,
.meshing-description {
  --columns: 1;

  display: grid;
  grid-template-columns: repeat(var(--columns), 1fr);

  @include mediaQuery(600, 'between', 800) {
    --columns: 2;
  }

  @include mediaQuery(800, 'between', 1000) {
    --columns: 3;
  }

  @include mediaQuery(1000) {
    --columns: 4;
  }
}

.tabContent ul {
  gap: 0.8em;
}

.meshing-description {
  --description-start-column: calc(var(--columns) - 1);

  column-gap: 1.2rem;
  margin-bottom: var(--hads-spacing-600);

  h2,
  p {
    margin-bottom: 0;
  }

  h2 {
    grid-column: 1 / var(--description-start-column);

    @include mediaQuery(800, 'max') {
      grid-column-end: -1;
    }
  }

  p {
    grid-column: var(--description-start-column) / -1;
  }
}
</style>
