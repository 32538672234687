<template>
  <ul class="tabContent">
    <slot />
  </ul>
</template>

<style lang="scss" scoped>
.tabContent > :deep(*:not(.active)) {
  display: none;
}
</style>
