<template>
  <div
    :class="['tabsBar', { isHorizontallyScrolled }]"
    role="tablist"
  >
    <button
      class="arrow-left"
      @click="goToStart"
    >
      <HaIcon :icon="faArrowLeft" />
    </button>
    <div
      ref="tabsWrapper"
      class="tabsWrapper"
    >
      <button
        v-for="tab of tabs"
        :key="tab"
        :class="{ active: tab === selectedTab }"
        role="tab"
        @click="$emit('selectTab', tab)"
      >
        {{ tab }}
      </button>
    </div>
    <button
      class="arrow-right"
      @click="goToEnd"
    >
      <HaIcon :icon="faArrowRight" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  faArrowRight,
  faArrowLeft
} from '@fortawesome/pro-solid-svg-icons'
import { HaIcon } from '@ha/components-v3'

defineProps<{
  tabs: any[]
  selectedTab: any
}>()

defineEmits(['selectTab'])

const tabsWrapper = ref()
const isHorizontallyScrolled = ref(false)

const goToEnd = () => {
  tabsWrapper.value.scrollLeft = tabsWrapper.value.scrollWidth

  const parentWidth = tabsWrapper.value.parentElement.offsetWidth
  const childWidth = tabsWrapper.value.scrollWidth

  tabsWrapper.value.style.transform = `translateX(${parentWidth - childWidth}px)`
  isHorizontallyScrolled.value = true
}

const goToStart = () => {
  tabsWrapper.value.style.transform = 'translateX(0)'
  isHorizontallyScrolled.value = false
}
</script>

<style scoped lang="scss">
.tabsBar {
  border-bottom: 1px solid var(--ha-color-border);
  margin-bottom: var(--hads-spacing-400);
  position: relative;
  overflow-x: clip;

  .tabsWrapper {
    display: flex;
    gap: var(--hads-spacing-300);
    transition: transform var(--hads-transition-simple);
  }

  .arrow-left,
  .arrow-right {
    color: var(--hads-color-iris);
    border: 1px solid currentcolor;
    padding: var(--hads-spacing-250);
    border-radius: var(--hads-radius-200);
    aspect-ratio: 1;
    position: absolute;
    top: 10%;
    background: var(--hads-theme-background);
    cursor: pointer;
    z-index: 2;
    display: flex;
  }

  .arrow-right {
    right: 0;
  }

  &.isHorizontallyScrolled .arrow-right,
  &:not(.isHorizontallyScrolled) .arrow-left {
    display: none;
  }
}

button {
  padding: var(--hads-spacing-200);
  white-space: nowrap;
  color: var(--hads-color-storm);

  &:not(.active):hover {
    cursor: pointer;
    color: var(--hads-color-iris);
  }

  &.active {
    font-weight: 700;
    position: relative;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -5%;
      bottom: -0.15em;
      height: 0.25em;
      width: 110%;
      background: var(--hads-color-iris);
      transition-duration: 0.4s;
      transform-origin: left center;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      @starting-style {
        scale: 0 1;
        opacity: 0;
      }
    }
  }
}

@include mediaQuery(600) {
  .tabsBar {
    .fa-arrow-left,
    .fa-arrow-right {
      top: 15%;
    }
  }

  button {
    padding: var(--hads-spacing-300);
  }
}

@media (width >= 780px) {
  .tabsBar {
    .tabsWrapper {
      transform: translateX(0) !important;
    }

    .arrow-left,
    .arrow-right {
      display: none;
    }
  }
}
</style>
