import type { Ref } from 'vue'

export function moveCaretAtEnd (DOMRef: Ref<HTMLElement>): void {
  const range = document.createRange()
  const selection = window.getSelection()
  range.setStart(DOMRef.value, DOMRef.value.childNodes.length)
  range.collapse(true)

  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

export function selectAllText (DOMRef: Ref<HTMLElement>): void {
  const range = document.createRange()
  range.selectNodeContents(DOMRef.value)
  const selection = window.getSelection()

  if (selection) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
}

export function clearSelection (): void {
  window.getSelection()?.removeAllRanges()
}

export type HTMLATTRContentEditable = boolean | 'inherit' | 'plaintext-only'

export const plainTextOnlyPolyfill = (): HTMLATTRContentEditable => {
  // TODO - Remove when better supported (https://caniuse.com/mdn-html_global_attributes_contenteditable_plaintext-only)

  if (import.meta.server) {
    return true
  }

  const domTestedElement = document.createElement('div')
  domTestedElement.setAttribute('contenteditable', 'plaintext-only')

  return domTestedElement.contentEditable as HTMLATTRContentEditable
}
