<template>
  <ul>
    <slot />
  </ul>
</template>

<style scoped lang="scss">
ul {
  display: flex;
  flex-direction: column;
  gap: var(--hads-spacing-100);
}
</style>
