<template>
  <span small>
    <slot />
  </span>
</template>

<style lang="scss" scoped>
[small] {
  color: var(--hads-color-midnight);
}
</style>
