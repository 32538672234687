import type { Article } from '@/models/Article.interface'

export function fetchBlogArticles(): Article[] {
  return [
    {
      title: 'Rejoignez Le Pain de l\'Amitié à Bordeaux !',
      textPreview: 'L’association Le Pain de l\'Amitié recherche des bénévoles pour soutenir son action auprès des personnes en difficulté. Venez participer et faire la différence dans votre quartier en apportant de l’aide concrète et un peu de chaleur humaine.',
      articleUrl: 'https://www.lepaindelamitie.fr/',
      meta: {
        thumbnailUrl: 'https://www.helloasso.com/public-documents/homepage/HA-Paindel_amitié-3.webp',
        tag: 'Ce mois-ci',
        publicationDate: 'Ce mois-ci',
        readDuration: '1 min'
      }
    },
    {
      title: 'Protéger la biodiversité avec la LPO',
      textPreview: 'La Ligue pour la Protection des Oiseaux mène des actions en faveur des oiseaux et de la nature partout en France. Avec des programmes de préservation, de sensibilisation et d’action,  les bénévoles de la LPO œuvrent pour un environnement durable et accueillant pour la faune.',
      articleUrl: 'https://www.lpo.fr/',
      meta: {
        thumbnailUrl: 'https://www.helloasso.com/public-documents/homepage/LPO_Liens.webp',
        tag: 'L\'initiative du mois',
        publicationDate: 'Ce mois-ci',
        readDuration: '1 min'
      }
    },
    {
      title: 'Besoin d\'un accompagnement ?',
      textPreview: 'Participez à un atelier de découverte en ligne de nos outils pour faciliter la gestion de votre association.',
      articleUrl: 'https://app.livestorm.co/helloasso',
      meta: {
        thumbnailUrl: 'https://cdn.helloasso.com/images/explore/edito/edito-formations.jpg',
        tag: 'Évènements',
        publicationDate: '11 janvier 2024',
        readDuration: '2 min'
      }
    }
  ]
}
