export enum CustomLinkTypes {
  cityObject
}

interface ButtonCommons {
  text: string
  properties?: any
  customLinkType?: CustomLinkTypes
}

export interface HrefLink extends ButtonCommons {
  href: string
}

export interface ToLink extends ButtonCommons {
  to: string
}

export interface NormalButton extends ButtonCommons {
  icon?: object
}

export type HADSButtonType = HrefLink | ToLink | NormalButton
