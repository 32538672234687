<template>
  <div class="section-title">
    <h2>
      <slot name="title" />
    </h2>
    <slot name="content" />
  </div>
</template>

<style scoped lang="scss">
h2 {
  margin-bottom: 0; // ! TODO - to remove when old styles.scss will be dropped.
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
}
</style>
