import type { Feature } from '@/models/Location.interface'

export async function getProbableCities(value = '', results = 1): Promise<Feature[]> {
  if (value.length < 3) {
    return []
  }

  const response = await fetch(
    `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
      value.replace(/ /gm, '+')
    )}&type=municipality&limit=${results}&autocomplete=1`
  )

  if (response.status === 200) {
    const { features } = await response.json()
    return features as Feature[]
  } else {
    console.error(response)
  }

  return []
}
